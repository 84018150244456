.lib-footer {
    --footer-accent-ff: var(--accent-ff);
    --footer-color: var(--color-accent);

    font-family: var(--footer-accent-ff);
    color: var(--footer-color);
    width: 100%;
    padding-bottom: max(80px, calc(env(safe-area-inset-bottom) + 80px));
}

.lib-footer .container-footer {
    max-width: 100%;
    margin-top: var(--size-small);
    border-top: 1px solid var(--color-grayish);
}
.lib-footer .content-footer {
    display: flex;
    justify-content: space-around;
}
.lib-footer .footer-logo {
    width: 59px;
    height: 61px;
    background-color: var(--color-accent);
    border-radius: 10px;
    font-size: var(--fz-small);
    text-align: center;
    padding-top: var(--size-normal);
}
.lib-footer .footer-logo-info {
    padding-top: var(--size-biggest);
}
.lib-footer .logo-text {
    color: white;
    font-size: var(--fz-tiny);
}
.lib-footer .footer-title-info {
    font-size: var(--size-normal-big);
    margin-bottom: var(--size-normal-big);
}
.lib-footer .footer-links{
    display: flex;
    justify-content: space-around;
    margin-top: var(--size-biggest);
    font-size: var(--fz-normal);
}
.lib-footer .items {
    padding-right: var(--size-small);
}
.lib-footer .footer-list {
    display: table-footer-group;
    flex-direction: column;
    list-style: none;
    font-size: var(--fz-normal);

}
.lib-footer .footer-list li {
    padding-top: var(--size-normal);
    font-size: 14px;
}
.lib-footer .footer-list a {
    font-size: 16px;
}
.lib-footer .footer-list-title{
    padding-left: var(--size-normal);
    font-size: 18px;
}
.footer-logo-img{
    display: none;
}
.accordion-info{
    display: none;
    margin-top: 20px;
}

.lib-footer .footer-links {
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.lib-footer .sidebar-social {
    display: flex;
    gap: 8px;
    list-style: none;
    padding: 0;
    margin: 0;
}
.lib-footer .sidebar-social .sidebar-social__facebook {
    background-color: #506098;
}
.lib-footer .sidebar-social .sidebar-social__viber {
    background-color: #f5f0f0;
}
.lib-footer .sidebar-social .sidebar-social__telegram {
    background-color: #08c;
}
.lib-footer .sidebar-social .sidebar-social__instagram {
    background: linear-gradient(45deg,#ffb700 14.65%,#ff6800 31.68%,#cb217d 54.72%,#7e00aa 85.35%);
}
.lib-footer .container-title{
    margin: 10px 20px;
}
.lib-footer .footer-list-city{
    font-size: 20px;
}

.lib-footer .sidebar-social a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    opacity: .85;
    transition: all .2s ease;
}
@media (max-width: 1024px) {
    .footer-logo-content{
        display: none;
    }
    .lib-footer .footer-links {
        display: none;
    }
    .lib-footer .content-footer{
        display: flex;
        flex-direction: column-reverse;
    }
    .lib-footer .container-footer {
        padding: 0 20px;
    }
    .lib-footer .footer-list-company{
        display: none;
    }
    .footer-form-input{
        display: flex;
        justify-content: flex-start;
    }
    .lib-footer .content-footer{
        padding-top: 30px;
        margin-top: 0;
    }
    .footer-logo-img{
        display: block;
        margin-bottom: 20px;
    }
    .lib-footer .footer-title-info-company{
        display: none;
    }
    .footer-title-info-client{
        display: none;
    }
    .accordion-info{
        display: block;
    }
}

