#root {
    width: initial;
}
body {
    font-size: initial;
    background-color: var(--color-lightest);
}

.layout {
    --top-nav-height: 50px;
}

.layout__content-wrapper {
    height: 100%;
    width: 100%;
    padding-top: var(--top-nav-height);
    display: flex;
    flex-direction: column;
}

.hot-link{
    height: 32px;
}
.call-menu{
    align-items: center;
    background-image: none;
    border: unset;
    border-radius: 0;
    box-shadow: 0 0 5px rgba(33,37,41,.12);
    cursor: pointer;
    display: flex;
    fill: #fff;
    height: 56px;
    justify-content: center;
    min-width: 56px;
    outline: none;
    padding: 0;
    position: relative;
    width: auto;
    background: var(--color-accent);
    border-radius: 10px;
}
.call-menu-wrapper{
    position: fixed;
    bottom: 20px;
    right: 25px;
    z-index: 1;
    display: flex;
}
.call-menu-text{
  border-radius: 20px;
  background: var(--color-accent);
  border: none;
  color: white;
  margin-left: 10px;
}

@keyframes ringing {
    0% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(-5px);
    }
    50% {
      transform: translateY(5px);
    }
    75% {
      transform: translateY(-3px);
    }
    100% {
      transform: translateY(0);
    }
  }
