.lib-header {
    --header-accent-ff: var(--accent-ff);
    --header-color: var(--color-accent);
    --header-height: 50px;

    display: flex;
    justify-content: space-between;
    background-color: var(--bg-color);
    z-index: 3;
    font-family: var(--header-accent-ff);
    color: var(--header-color);
    position: fixed;
    width: 100%;
    height: var(--header-height);
    padding: 0 var(--size-normal);
    top: 0;
    transition: .3s;
}
.lib-header .header-main-logo-img{
    width: 285px;
}

.lib-header .lib-header__btn {
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
}

.lib-header.header_transparent, .lib-header.header_transparent .lib-link {
    background-color: transparent;
    color: var(--color-lightest);
}

.lib-header.header_transparent {
    background-color: rgba(0, 0, 0, .18);
    box-shadow: 0 14px 33px 25px rgba(0, 0, 0, 0.18);
}

.lib-header.header_transparent .react-select__control {
    background: rgba(255, 255, 255, 0.32);
}

.lib-header.header_transparent .react-select__placeholder {
    color: var(--color-lightest);
}

.lib-header .menu {
    display: none;
    margin-right: var(--size-small);
}

.header-main-logo {
    display: flex;
    align-items: center;
    width: 60%;
    flex: 1;
    justify-content: center;
}
.header-left-navigation {
    display: flex;
    cursor: pointer;
    align-items: center;
    list-style: none;
    font-size: var(--fz-normal);
    width: 20%;
    flex: 1;
}
.header-list-navigation {
    white-space: nowrap;
    display: flex;
    cursor: pointer;
    align-items: center;
    list-style: none;
    font-size: var(--fz-normal);
    margin: 0;
    padding: 0;
    height: 100%;
}
.header-list-navigation .lib-link {
    font-size: var(--fz-normal);
}
.header-links-icon {
    margin-left: var(--size-normal-bigger);
    fill: var(--color-accent);
}
.header-links-icon.cart, .header-links-icon.heart {
    position: relative;
}
.header-links-icon .cart-badge {
    position: absolute;
    right: -3px;
    bottom: 13px;
    z-index: 2;
    background: var(--header-color);
    color: var(--color-lightest);
    width: 14px;
    height: 14px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -1px 1px 0 0 var(--color-lightest);
    border-radius: 7px;
    font-size: var(--fz-tinier);
    line-height: var(--line-h-smaller);
    text-align: center;
}
.lib-header.header_transparent .header-links-icon {
    fill: var(--color-lightest);
}
.lib-header.header_transparent .header-links-icon.menu path {
    stroke: var(--color-lightest);
}

.lib-header .header-links-icon.menu path {
    stroke: var(--color-accent);
}
.header-links-icon:first-child {
    margin-left: 0;
}
.list-group {
    padding-right: 25px;
    height: 100%;
    display: flex;
    align-items: center;
}
.header-right-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 20%;
    flex: 1;
}
.header-right-menu form {
    display: flex;
    align-items: center;
}
.header-input-search {
    height: 36px;
    font-size: var(--fz-normal);
    padding: 0 16px 0 40px;
    line-height: normal;
    background: var(--color-grayish);
    color: var(--color-accent);
    border-radius: 4px;
    border: 0;
}

.header-search-second {
    display: none;
}
.header-list-collection {
    position: absolute;
    top: 100%;
    left: 0;
    width: 450px;
    box-shadow: 0 6px 10px -15px #111;
    transition: .3s;
    background-color: var(--color-lightest);
    color: var(--header-color);
    z-index: 100;
    opacity: 0;
    height: 100vh;
    visibility: hidden;
}

.list-group:hover .header-list-collection {
    visibility: visible;
    opacity: 1;
}
.react-select__indicator-separator{
    display: none;
}

@media (max-width: 500px) {
    .header-links-icon.user {
        display: none;
        user-select: none;
    }
    .header-links-icon.dollar {
        display: none;
        user-select: none;
    }
    .header-links-icon.heart {
        margin-left: 0;
    }
}

@media (max-width: 1023px) {
    .header-right-menu .header-input-form {
        display: none;
        user-select: none;
    }
}

@media (max-width: 1094px) {
    .header-list-navigation {
        display: none;
        user-select: none;
    }
    .lib-header .menu {
        display: block;
    }
    .header-search-second {
        display: block;
    }
    .header-links-icon {
        margin-left: var(--size-mid-bigger);
    }
}
